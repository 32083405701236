import axios from 'axios'

const hamburgerMenu = document.querySelector('.hamburger-menu')
const popupMenu = document.querySelector('.popup-menu')
const documentRoot = document.querySelector('html')
const closeMenu = document.querySelector('.close-menu')
const formDom = document.querySelectorAll('.app-form')
const outerPopup = document.querySelector('.outer-page')
const outerClose = document.querySelector('.outer-close')
const headerDom = document.querySelector('header')
const scrollTopTop = document.querySelector('.scroll-top-top')
const ihClass = document.querySelector('.ih--class')

const imageUrl = require('../img/home-bg1.jpg')

function headerScroll() {
  const wScroll = window.scrollY
  if (wScroll > 40) {
    headerDom.classList.add('header--scroll')
  } else {
    headerDom.classList.remove('header--scroll')
  }

  if (scrollTopTop) {
    if ((window.innerHeight + window.scrollY) >= document.body.offsetHeight) {
      scrollTopTop.classList.add('stt--show')
    } else {
      scrollTopTop.classList.remove('stt--show')
    }
  }
}

headerScroll()

window.addEventListener('scroll', headerScroll)

function togglePopupMenu () {
  popupMenu.classList.toggle('pm--show')
  documentRoot.classList.toggle('no-scroll')
}

if (scrollTopTop) {
  scrollTopTop.addEventListener('click', () => {
    window.scrollTo({top: 0, behavior: 'smooth'})
  })
}

hamburgerMenu.addEventListener('click', togglePopupMenu)
closeMenu.addEventListener('click', togglePopupMenu)

function setInputFilter(textbox, inputFilter) {
  ['input', 'keydown', 'keyup', 'mousedown', 'mouseup', 'select', 'contextmenu', 'drop'].forEach(function (event) {
    textbox.addEventListener(event, function () {
      if (inputFilter(this.value)) {
        this.oldValue = this.value;
        this.oldSelectionStart = this.selectionStart;
        this.oldSelectionEnd = this.selectionEnd;
      /*  eslint no-prototype-builtins: ["off"] */
      } else if (this.hasOwnProperty('oldValue')) {
        this.value = this.oldValue;
        this.setSelectionRange(this.oldSelectionStart, this.oldSelectionEnd);
      }
    });
  });
}

document.querySelectorAll('.input__number-only').forEach((input) => {
  setInputFilter(input, function (value) {
    return /^\d*\.?\d*$/.test(value);
  })
})

const serialize = (formEl) => {
  const formData = [];
  for (let i = 0; i < formEl.elements.length; i++) {
    const field = formEl.elements[i];
    if (field.name && !field.disabled && field.type !== 'button' && field.type !== 'file' && field.type !== 'reset' && field.type !== 'submit') {
      formData[formData.length] = `${encodeURIComponent(field.name)}=${encodeURIComponent(field.value)}`;
    }
  }
  return formData.join('&').replace(/%20/g, '+');
};

/*  eslint func-names: ["off"] */
/*  eslint prefer-arrow-callback: ["off"] */
formDom.forEach((form) => {
  form.addEventListener('submit', function (e) {
    e.preventDefault();
    const data = serialize(this);
    const btnSubmit = this.querySelector('[type="submit"]');
    const btnText = btnSubmit.textContent;
    btnSubmit.setAttribute('disabled', 'true');
    btnSubmit.textContent = 'Submitting...';
    axios.post('https://fokustarget.com/form.php', data)
      .then((res) => {
        if (res.data.success) {
          outerPopup.classList.toggle('op--show')
          this.reset()
          grecaptcha.reset()
          btnSubmit.removeAttribute('disabled')
          btnSubmit.textContent = btnText
          documentRoot.classList.toggle('no-scroll')
        } else {
          swal('Opps', res.data.message, 'error');
        }
      })
      .catch((err) => {
        // swal('Opps', 'We are under maintenance', 'error');
        console.log(err);
      })
      .finally(() => {
        this.reset();
        grecaptcha.reset();
        btnSubmit.removeAttribute('disabled');
        btnSubmit.textContent = btnText;
      });
  })
})

function closePopup () {
  outerPopup.classList.toggle('op--show')
  documentRoot.classList.toggle('no-scroll')
}

if (outerPopup) {
  outerPopup.addEventListener('click', (e) => {
    if (e.target.classList.contains('outer-page')) {
      closePopup()
    }
  })
}

if (outerClose) {
  outerClose.addEventListener('click', closePopup)
}

const url = imageUrl

function rendered() {
  if (ihClass) {
    ihClass.classList.add('index-hero')
  }
}

function startRender() {
  requestAnimationFrame(rendered)
}

const img = new Image()
img.onload = function() {
  requestAnimationFrame(startRender)
}
img.src = url
if (img.complete) {
  img.onload()
}