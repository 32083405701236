import Swiper from 'swiper'

const sbctButton = document.querySelectorAll('.sbct--button')

window.onload = () => {
  const swiper = new Swiper('.swiper-container', {
    slidesPerView: 1,
    spaceBetween: 0
  })

  if (sbctButton) {
    sbctButton.forEach((b) => {
      b.addEventListener('click', function() {
        const { sbct } = this.dataset
        if (sbct === 'left') {
          swiper.slidePrev()
        } else {
          swiper.slideNext()
        }
      })
    })
  }
}