import Swiper from 'swiper'

const sbwButton = document.querySelectorAll('.sbw--button')

let slidesPerView = 1.9

if (window.outerWidth < 768) {
  slidesPerView = 1
}

const swiperWorks = new Swiper('.swiper-container-works', {
  slidesPerView,
  initialSlide: 1,
  spaceBetween: 15,
  centeredSlides: true,
  navigation: {
    nextEl: '.swiper-button-next-works',
    prevEl: '.swiper-button-prev-works'
  }
})

if (sbwButton) {
  sbwButton.forEach((b) => {
    b.addEventListener('click', function() {
      const { sbw } = this.dataset
      if (sbw === 'left') {
        swiperWorks.slidePrev()
      } else {
        swiperWorks.slideNext()
      }
    })
  })
}